import React, { Suspense, useEffect, useRef, useState } from 'react';
import { Canvas } from '@react-three/fiber';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import {
  ATFWidget,
  LayoutProvider,
  TAnimation,
  TextAnimation
} from '@lam-agency/toolkit/components';
import { getCSSVariableValue } from '@lam-agency/toolkit/utils';
import { IWidgetProps } from '@lam-agency/toolkit/components/Common/ATFWidget';
import gsap from 'gsap';

import Lights from './Lights';
import ImagePlane from './ImagePlane';

interface IFields extends IWidgetProps {
  body: string;
  images: string[];
}
interface IProps {
  data: {
    fields: IFields;
  };
}

const FigmaCopyOnImage = ({
  data: {
    fields: { assetLinks, body, contactURL, quickLinks }
  }
}: IProps) => {
  const [dimensions, setDimensions] = useState({
    width: 0,
    normalizedWidth: 0,
    height: 0,
    normalizedHeight: 0,
    aspect: 0
  });

  const containerRef = useRef<HTMLDivElement>(null);
  const cursorRef = useRef<HTMLDivElement>(null);
  const prevMousePosRef = useRef({ x: 0, y: 0 });

  const textAnimation = getCSSVariableValue(
    '--atf-text-animation'
  ) as TAnimation;

  //

  const pixelsToCamera = (pixelWidth: number) => {
    const SCALE_FACTOR = 0.01;

    return pixelWidth * SCALE_FACTOR;
  };

  //

  useEffect(() => {
    if (
      !containerRef?.current ||
      !cursorRef?.current ||
      typeof window === `undefined`
    ) {
      return;
    }

    const cursor = cursorRef.current;
    const container = containerRef.current;

    //

    const handleResize = () => {
      if (!containerRef.current) return;

      const boundingRect = container.getBoundingClientRect();

      const { width, height } = boundingRect;

      const aspect = width / height;
      const cameraWidth = pixelsToCamera(width);

      setDimensions({
        width,
        height,
        aspect,
        normalizedWidth: cameraWidth,
        normalizedHeight: cameraWidth * (1 / aspect)
      });
    };

    window.addEventListener(`resize`, handleResize);

    handleResize();

    //

    const handleMouseMove = (event: MouseEvent) => {
      const { clientX, clientY } = event;
      const { x: prevX, y: prevY } = prevMousePosRef.current;

      gsap.killTweensOf(cursor);

      const dx = clientX - prevX;
      const rotation = dx > 0 ? 40 : -40;

      gsap.to(cursor, {
        ease: 'power4.out',
        duration: 0.5,
        x: clientX,
        y: clientY,
        rotate: rotation
      });

      gsap.to(cursor, {
        delay: 0.05,
        rotate: 0,
        duration: 0.5,
        ease: 'power4.out'
      });

      prevMousePosRef.current = { x: clientX, y: clientY };
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener(`resize`, handleResize);
      window.removeEventListener(`mousemove`, handleMouseMove);
    };
  }, []);

  //

  const meshWidth = dimensions.normalizedWidth * 0.8;
  const meshHeight = meshWidth * 0.6;

  return (
    <>
      <div ref={cursorRef} className={styles.cursor}>
        <svg viewBox="0 0 118.99 186.52" className={styles.hand}>
          <path
            className="cls-1"
            d="M114.93,49.27C106.38,14.34,93.32,1.02,61.9.01c-7.04-.25-26.39,2.76-37.95,11.81-7.79,6.79-13.82,14.58-17.34,25.13C1.58,52.54-.43,68.63.07,84.96c.25,15.33,0,36.69,1.76,52.02,2.51,27.64,14.83,45.24,49.51,48.76,10.56,1.01,21.61,1.51,31.67-1.51,10.81-3.27,24.38-8.04,29.15-19.35,4.02-9.05,6.53-17.59,6.53-27.65.75-22.12.25-72.13-3.77-87.96ZM93.29,85.15c-.12,8.96.12,23.91-.82,32.82-.94,8.92-6.75,19.81-15.04,23.21-3.63,1.49-9.51,2.32-13.42,2.06-.75-.05-1.48-.11-2.2-.17-11.14-1.03-21-7.63-26.25-17.5-3.06-5.75-6.9-14.04-9.21-22.94,0,0-2.88-11.55,2.15-12.73,5.03-1.18,9.6,19.76,13.28,14.31,2.84-4.21-.42-11.56-2-16.39-2.06-6.29-6.61-17.09-6.02-23.04.12-1.21.28-2.47.98-3.47.7-.99,2.11-1.6,3.17-1,.61.34.97.98,1.27,1.61,3.84,8.11,10.32,22.22,12.72,30.86.35,1.27,1.45,2.83,2.7,1.98.47-.31.75-.85.77-1.41.2-4.67-1.25-8.28-1.39-12.42-.31-8.88-2.12-20.94-2.43-29.82-.05-1.51-.1-3.06.4-4.49.5-1.43,2.45-3.17,3.95-3.35,1.67-.2,3.25,1.03,3.99,2.54.74,1.51.83,3.25.9,4.93.33,7.68,3.23,19.01,3.56,26.69.14,3.35.3,6.76,1.02,10.02.47,2.14,3.62,1.87,3.69-.33,0-.02,0-.04,0-.06.23-7.83,2.13-22.11,2.18-29.94.01-1.74.04-3.55.85-5.09.82-1.54,2.69-2.66,4.31-2.03,1.91.74,2.32,3.21,2.44,5.25.55,9.3-1.08,22.69-.54,31.99.06,1.09.42,2.5,1.51,2.56,5.41.32,4.65-10.87,4.65-10.87,0,0-.15-14.36,5.77-13.03,4.18.94,3.11,13.7,3.03,19.24Z"
          />
        </svg>
      </div>

      <div ref={containerRef} className={styles.container}>
        <div className={styles.background}>
          <Canvas
            camera={{ position: [0, 0, 100], near: 0, far: 1000, zoom: 100 }}
            orthographic
            shadows
          >
            <Suspense fallback={null}>
              <Lights />

              <group rotation={[-0.6, 0, 0.2]}>
                <ImagePlane width={meshWidth} height={meshHeight} />
              </group>
            </Suspense>
          </Canvas>
        </div>

        <LayoutProvider grid paddingX paddingY className={styles.layout}>
          <div className={styles.textWrapper}>
            <TextAnimation
              className={cn('d1')}
              text={body}
              animation={textAnimation}
              speed={0.03}
            />
          </div>

          <ATFWidget
            contactURL={contactURL}
            assetLinks={assetLinks}
            quickLinks={quickLinks}
          />
        </LayoutProvider>
      </div>

      <div aria-hidden className={styles.scrollPadding} />
    </>
  );
};

export default FigmaCopyOnImage;
