import React, { useRef } from 'react';
import * as THREE from 'three';

const Lights = () => {
  const pointLightRef = useRef<THREE.PointLight>(null);

  return (
    <>
      {/* <ambientLight intensity={1} /> */}

      <pointLight
        ref={pointLightRef}
        position={[0, 2, 2]}
        intensity={1}
        castShadow
      />
    </>
  );
};

export default Lights;
